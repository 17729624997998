@import '~antd/dist/antd.css';

.ant-picker {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
}

.ant-picker:hover {
  border: 1px solid rgba(0, 0, 0, 0.87);
}

.ant-picker-focused {
  border: 1px solid rgba(0, 0, 0, 0.87);
  box-shadow: none;
}

.ant-picker-range .ant-picker-active-bar {
  background: rgba(0, 0, 0, 0.87);
}

/* override background color for the start and end date of the selected range */
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: rgb(196, 0, 0);
}

/* override border color for the end date of the selected range */
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #c40000;
}

/* override background color for the dates in-between the selected range */
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  /* background: #ffdddd; */
  background: rgba(196, 0, 0, 0.144);
}

/* override background color for the dates in-between the selected range on hover */
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after {
  background: rgba(196, 0, 0, 0.24);
}

/* overrides for the date preset buttons */
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: #333;
  background: #f4f4f4;
  border-color: #333;
  border-radius: 2px;
  padding: 0 1rem;
  cursor: pointer;
}
